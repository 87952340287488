.contact-mainContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 75px;
    width: 100%;
}

.mail {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.mail input {
    border: #460007 solid 2px;
    border-radius: 15px;
    background-color: #0004;
    padding: 5px;
    color: white;
    font-size: large;
}

.body {
    display: flex;
    flex: 5;
    justify-content: center;
    align-items: center;
}
.body textarea {
    width: 90%;
    height: 90%;
    resize: none;
    border: #460007 solid 2px;
    border-radius: 15px;
    background-color: #0004;
    padding: 5px;
    color: white;
    font-size: large;
}

.send {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.send button {
    background-color: #460007;
    border-radius: 15px;
    padding: 10px;
    color: white;
    font-size: large;
}

.alert {
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 15px;
    padding: 5px;
}

.ok {
    background-color: green;
}

.notOk {
    background-color: red;
}