@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeContainer {
    display: flex;
    flex: 1 1;
}

.desktop-meImage {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.desktop-meImage img {
    margin: 100px;
    min-height: 0;
    min-width: 0;
    width: 50%;
    border-radius: 500px;
    border: solid 5px #460007;
}

.desktop-info {
    display: flex;
    flex: 1.7 1;
    flex-direction: column;
}

.desktop-details {
    display: flex;
    flex: 4 1;
    margin: 25px;
    margin-left: -50px;
    background-color: #460007;
    border-radius: 15px;
    padding: 10px;
}

.desktop-details table {
    font-size: xx-large;
    width: 100%;
}

.desktop-details a {
    color: white;
}

.desktop-info td {
    padding: 15px;
}

.desktop-linkGroup {
    display: flex;
    flex: 1 1;
    margin: 25px;
    margin-left: -50px;
    background-color: #460007;
    border-radius: 50000px;
}

.desktop-linkGroup button {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: xx-large;
    cursor: pointer;
}

.desktop-linkGroup button:not(:last-child) {
    border-right: solid 3px #282828;
}
.menuContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 108vh;
    background-color: #93000e;
    z-index: 10;
}

.menuContainer > div {
    position: fixed;
    top: 5px;
    left: 5px
}

.menuContainer ul {
    margin-top: 150px;
    list-style-type: none;
    margin-left: -2em;
}

.menuContainer li {
    margin-bottom: 25px;
    margin: 5px;
    padding: 5px;
    display: flex;
    justify-items: center;
    align-items: center;
    background-color: transparent;
    border-radius: 15px;
    transition: linear 0.25s ;
}

.menuContainer li:hover:not(.selected) {
    background-color: #282828;
    border-radius: 15px;
}

.menuContainer li.selected {
    background-color: #282828;
    border-radius: 15px;
}

.label {
    display: flex;
    flex: 5 1;
    font-size: 2em;
    align-items: center;
    justify-content: center;
}

.icon {
    display: flex;
    flex: 1 1;
    width: 100%;
    font-size: 3.4em;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}
#nav-icon {
  width: 60px;
  height: 45px;
  margin: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: fixed;
  height: 8px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}
.content { 
    margin-left: 75px;
    display: flex;
    flex: 1 1;
    width: calc(100% - 75px);
    height: 100vh;
}
.experienceContainer {
    display: flex;
    flex: 1 1;
    height: 100vh;
}

.experienceContainer > div {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.experienceContainer > div h1 {
    width: 95%;
    margin: 15px;
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 3px dashed #0004;
}

.experienceContainer > div:not(:last-child) {
    border-right: dashed 3px #0004;
}

.card {
    border: solid 2px #430007;
    border-radius: 5px;
    background-color: #282828;
    margin: 10px;
}

.card > .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    font-size: larger;
}

.card > .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff8;
    text-align: right;
}

.card > .footer, .card > .header {
    background-color: #fff2;
    padding: 5px;
}

.card > .card-body {
    text-align: left;
    padding: 10px;
}

.list {
    list-style: none;
}

.list li {
    padding-bottom: 5px;
}

.list li::before {
    content: '- ';
    color: white;
    width: 1em;
    margin-left: -1em;
}
.projectsContainer {
    display: flex;
    flex: 1 1;
    height: 100vh;
}

.projectsContainer > div {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.projectsContainer > div:last-child {
    border-left: dashed 3px #430007;
}

.projectCategory {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.projectCategory:last-child {
    border-top: dashed 3px #430007;
}

.projectCategory > h1 {
    display: flex;
    flex: 1 1;
    justify-content: center;
    width: 90%;
    padding-bottom: 15px;
    border-bottom: solid 3px #0004;
}

.projectCategory > div {
    display: flex;
    flex: 10 1;
    flex-wrap: wrap;
}

.projectLogo {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    cursor: pointer;
}

.projectLogo img {
    width: 80%;
    display: inline;
}

.projectLogo span {
    font-size: larger;
    text-align: center;
}
.projectContainer {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100vh;
}

.projectContainer nav {
    flex: 1 1;
}

.backButton {
    background-color: #97000e;
    border-radius: 15px;
    color: white;
    margin: 10px 5px 5px 15px;
    padding: 5px;
    font-size: 1.25em;
    cursor: pointer;
}

.projectContainer .project-title {
    display: flex;
    flex: 2 1;
    justify-content: center;
}

.projectContainer .project-title > h1 {
    width: 90%;
    text-align: center;
    border-bottom: solid 3px #0004;
    padding-bottom: 15px;
}

.project-content {
    /*background-color: yellowgreen;*/
    display: flex;
    flex: 20 1;
}

.desktop-images {
    display: flex;
    flex: 1 1;
    align-items: center;
}

.desktop-images > div {
    width: 100%;
}

.desktop-images > div > img {
    width: 100%;
}

.desktop-info {
    display: flex;
    flex: 1 1;
}

.desktop-info > div:first-child {
    display: flex;
    flex: 3 1;
    flex-direction: column;
    width: 100%;
}

.project-summary {
    display: flex;
    flex: 2 1;
    flex-direction: column;
    align-items: center;
}

.project-summary h2 {
    font-weight: bold;
    border-bottom: solid 2px #0004;
    padding-bottom: 5px;
    width: 75%;
    text-align: center;
}

.project-summary span {
    width: 70%;
    font-size: larger;
}

.project-tech {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
}

.project-tech h3 {
    font-weight: bold;
    border-bottom: solid 2px #0004;
    padding-bottom: 5px;
    width: 75%;
    text-align: center;
}

.project-tech > span {
    width: 45%;
}

.project-tech > span div span {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.project-tech > span div span:first-child {
    justify-content: flex-start;
    flex: 1 1;
}
.project-tech > span div span:last-child {
    justify-content: flex-start;
    flex: 2 1;
}

.project-tech > span div {
    display: block;
    border-bottom: solid 1px #0008;
    display: flex;
    margin: 5px;
}

.project-links {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
}

.project-links h3 {
    font-weight: bold;
    border-bottom: solid 2px #0004;
    padding-bottom: 5px;
    width: 75%;
    text-align: center;
}

.project-links a {
    color: #430007
}

.project-image:not(.mobile) img {
    width: 100%;
}
.project-image.mobile img {
    height: 80vh;
}
.project-image.mobile {
    text-align: center;
}

.project-image-caption {
    border-radius: 15px;
    background-color: #000c;
}

.project-image-caption h4, .project-image-caption p {
    color: white;
}
/*!
 * Bootswatch v5.1.1
 * Homepage: https://bootswatch.com
 * Copyright 2012-2021 Thomas Park
 * Licensed under MIT
 * Based on Bootstrap
*/
/*!
 * Bootstrap v5.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #375a7f;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #e74c3c;
  --bs-orange: #fd7e14;
  --bs-yellow: #f39c12;
  --bs-green: #00bc8c;
  --bs-teal: #20c997;
  --bs-cyan: #3498db;
  --bs-white: #fff;
  --bs-gray: #888;
  --bs-gray-dark: #303030;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #ebebeb;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #888;
  --bs-gray-700: #444;
  --bs-gray-800: #303030;
  --bs-gray-900: #222;
  --bs-primary: #375a7f;
  --bs-secondary: #444;
  --bs-success: #00bc8c;
  --bs-info: #3498db;
  --bs-warning: #f39c12;
  --bs-danger: #e74c3c;
  --bs-light: #adb5bd;
  --bs-dark: #303030;
  --bs-primary-rgb: 55, 90, 127;
  --bs-secondary-rgb: 68, 68, 68;
  --bs-success-rgb: 0, 188, 140;
  --bs-info-rgb: 52, 152, 219;
  --bs-warning-rgb: 243, 156, 18;
  --bs-danger-rgb: 231, 76, 60;
  --bs-light-rgb: 173, 181, 189;
  --bs-dark-rgb: 48, 48, 48;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg-rgb: 34, 34, 34;
  --bs-font-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #fff;
  --bs-body-bg: #222;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.contact-mainContainer {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding-top: 75px;
    width: 100%;
}

.mail {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.mail input {
    border: #460007 solid 2px;
    border-radius: 15px;
    background-color: #0004;
    padding: 5px;
    color: white;
    font-size: large;
}

.body {
    display: flex;
    flex: 5 1;
    justify-content: center;
    align-items: center;
}
.body textarea {
    width: 90%;
    height: 90%;
    resize: none;
    border: #460007 solid 2px;
    border-radius: 15px;
    background-color: #0004;
    padding: 5px;
    color: white;
    font-size: large;
}

.send {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.send button {
    background-color: #460007;
    border-radius: 15px;
    padding: 10px;
    color: white;
    font-size: large;
}

.alert {
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 15px;
    padding: 5px;
}

.ok {
    background-color: green;
}

.notOk {
    background-color: red;
}
.mainContainer {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.meImg {
    display: flex;
    flex: 5 1;
    align-items: center;
    justify-content: center;
}

.meImg img {
    min-height: 0;
    min-width: 0;
    width: 50%;
    border-radius: 500px;
    border: solid 5px #460007;
}

.details {
    display: flex;
    flex: 4 1;
}

.details table {
    background-color: #460007;
    border-radius: 10px;
    margin: 10px;
    padding: 5px;
    width: 100%;
}

.details td {
    padding: 4px;
}

.details a {
    color: white;
}

.odd {
    background-color: #0003;
}

.detailsInfo {
    text-align: right;
}

.linkGroup {
    display: flex;
    flex: 1 1;
    width: 90%;
    background-color: #460007;
    margin: 10px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
}

/*.linkGroup button {
    display: flex;
    flex: 1;
    justify-content: center;
    font-size: larger;
    color: white;
    line-height: 350%;
    height: 100%;
}*/

.linkGroup button {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    font-size: larger;
    color: white;
    height: 100%;
    cursor: pointer;
}

.linkGroup .notLast {
    border-right: #282828 solid 2px;
}
.menuButton {
    position: fixed;
    left: 10px;
    top: 5px;
    /*padding: 5px;
    height: 60px;
    width: 60px;
    background-color: #93000e;
    border-radius: 15px;
    border: #282828 solid 2px;*/
    z-index: 10;
}

button {
    border: none;
    background-color: transparent;
}

.menu {
    position: fixed;
    background-color: #93000e;
    width: 50%;
    height: 4000px;
    left: 0;
    top: 0;
    z-index: 9;
}

.menu ul {
    display: inline;
    list-style-type: none;
    width: 100%;
}

.menu li {
    position: relative;
    margin-bottom: 25%;
    width: 100%;
    text-align: center;
}

.menu button {
    /*border-radius: 10px;
    border: solid 2px #282828;*/
    padding: 5px;
    font-size: large;
    color: white;
    text-decoration: none;
    cursor: pointer;
    width: 90%;
}

/*.menu a:visited {
    color: rgb(5, 0, 77);
}*/
#nav-icon {
  width: 60px;
  height: 45px;
  position: relative;
  margin: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  top: 7px;
  left: 0px;
}

#nav-icon span {
  display: block;
  position: fixed;
  height: 8px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}
.collapse {
    margin-top: 10px;
    background-color: #0004;
    border-radius: 15px;
}

.collapse-button {
    display: block;
    width: 100%;
    background-color: #0004;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    font-size:medium;
    cursor: pointer;
}

.isCollapse {
    border-radius: 15px;
}

.collapse-content {
    padding-bottom: 5px;
}
  
.collapse-content.collapsed {
    display: none;
}

.collapsed-content.expanded {
    display: block;
}
.mainContainer {
    display: flex;
    flex: 1 1;
    width: 100%;
    align-items: center;
}
.experience {
    margin-top: 65px;
}

.section {
    padding: 5px;
}

.section ul {
    list-style: none;
}
.section li::before {
    content: '- ';
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.item {
    width: 100%;
    padding-bottom: 10px;
}

.item:not(:last-child) {
    border-bottom: solid 1px #0006;
}

.item h3 {
    text-align: center;
}

.experienceNav {
    align-items: flex-end;
    position: absolute;
    top: 20px;
    right: 10px;
}

.experienceNav button {
    background-color: #93000e;
    padding: 5px;
    border-radius: 15px;
    color: white;
    font-size: larger;
    cursor: pointer;
}
.accordion {
    border-radius: 18px;
    border: 2px solid #460007;
    margin: 10px;
}
.accordion .accordion-item:first-child .title {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.accordion .accordion-item:last-child .title {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.accordion .accordion-item .title {
    background-color: #460007;
    color: white !important;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: justify;
    border: none;
    outline: none;
    transition: 1.0s;
}
.accordion .accordion-item .title:after {
    content: '\02795';
    color: white !important;
    /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    float: right;
    margin-left: 5px;
}
.accordion .accordion-item .panel {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}
.accordion .accordion-item.active .title {
    background-color: #380006;
}
.accordion .accordion-item.active .title:after {
    content: '\2796';
    color: white !important;
    /* Unicode character for "minus" sign (-) */
}
.accordion .accordion-item.active .panel {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}
  
.info {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    text-align: center;
}

.info h2 {
    text-decoration: underline;
    font-weight: bold;
}

.info div {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.info button {
    color: #93000e;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.images {
    display: flex;
    flex: 1 1;
}

.image, .mobile-image {
    width: 100%;
    display: flex;
    position: relative;
}

.mobile-image {
    justify-content: center;
}

.image img {
    width: 100%;
}

.image span, .mobile-image span {
    position: absolute;
    border-radius: 15px;
    font-size: 0.7em;
    background-color: #000c;
    text-align: center;
    padding: 5px;
    bottom: 5px;
    left: 50%;
    width: 90%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.mobile-image img {
    width: 50%;
}

.modal {
    display: flex;
    flex: 1 1;
    height: 80vh;
    margin: 65px 10px 10px 10px;
    background-color: #282828;
    border-radius: 15px;
    flex-direction: column;
    padding: 5px;
}

.modal > .nav {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}

.modal > .nav button {
    margin: 5px;
    border-radius: 15px;
    background-color: #460007;
    width: 3.5em;
    height: 3.5em;
}

.modal > .info {
    display: flex;
    flex: 10 1;
}

.modal > .tech {
    display: flex;
    flex: 10 1;
    flex-direction: column;
    text-align: center;
}

.modal > .info h3, .modal > .tech h3 {
    border-bottom: solid 1px #fff4;
}

.projectsButton {
    position: fixed;
    top: 10px;
    right: 5px;
    color: white;
    border-radius: 15px;
    background-color: #93000e;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
}

.projects-mainContainer {
    width: 100%;
    position: fixed;
    top: 65px;
    left: 0;
}

.project {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    align-items: center;
    width: 50%;
    cursor: pointer;
}

.project img {
    width: 50%;
    display: inline;
}
.project span {
    margin-top: 5px;
    text-align: center;
}


.section {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    padding: 5px;
    flex-shrink: 2;
}

.scroll-clue {
    position: absolute;
    bottom: 150px;
    right: 20px;
    font-size: 3em;
}
.mainContainer {
    display: flex;
    flex: 1 1;
    height: 92vh;
    width: 100%;
}

.coming-soon {
    height: 100vh;
    width: 100%;
    line-height: 80vh;
    padding: 5px;
}

.coming-soon p {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    font-size: xx-large;
}
