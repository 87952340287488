.projectContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
}

.projectContainer nav {
    flex: 1;
}

.backButton {
    background-color: #97000e;
    border-radius: 15px;
    color: white;
    margin: 10px 5px 5px 15px;
    padding: 5px;
    font-size: 1.25em;
    cursor: pointer;
}

.projectContainer .project-title {
    display: flex;
    flex: 2;
    justify-content: center;
}

.projectContainer .project-title > h1 {
    width: 90%;
    text-align: center;
    border-bottom: solid 3px #0004;
    padding-bottom: 15px;
}

.project-content {
    /*background-color: yellowgreen;*/
    display: flex;
    flex: 20;
}

.desktop-images {
    display: flex;
    flex: 1;
    align-items: center;
}

.desktop-images > div {
    width: 100%;
}

.desktop-images > div > img {
    width: 100%;
}

.desktop-info {
    display: flex;
    flex: 1;
}

.desktop-info > div:first-child {
    display: flex;
    flex: 3;
    flex-direction: column;
    width: 100%;
}

.project-summary {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
}

.project-summary h2 {
    font-weight: bold;
    border-bottom: solid 2px #0004;
    padding-bottom: 5px;
    width: 75%;
    text-align: center;
}

.project-summary span {
    width: 70%;
    font-size: larger;
}

.project-tech {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.project-tech h3 {
    font-weight: bold;
    border-bottom: solid 2px #0004;
    padding-bottom: 5px;
    width: 75%;
    text-align: center;
}

.project-tech > span {
    width: 45%;
}

.project-tech > span div span {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.project-tech > span div span:first-child {
    justify-content: flex-start;
    flex: 1;
}
.project-tech > span div span:last-child {
    justify-content: flex-start;
    flex: 2;
}

.project-tech > span div {
    display: block;
    border-bottom: solid 1px #0008;
    display: flex;
    margin: 5px;
}

.project-links {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.project-links h3 {
    font-weight: bold;
    border-bottom: solid 2px #0004;
    padding-bottom: 5px;
    width: 75%;
    text-align: center;
}

.project-links a {
    color: #430007
}

.project-image:not(.mobile) img {
    width: 100%;
}
.project-image.mobile img {
    height: 80vh;
}
.project-image.mobile {
    text-align: center;
}

.project-image-caption {
    border-radius: 15px;
    background-color: #000c;
}

.project-image-caption h4, .project-image-caption p {
    color: white;
}