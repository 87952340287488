.collapse {
    margin-top: 10px;
    background-color: #0004;
    border-radius: 15px;
}

.collapse-button {
    display: block;
    width: 100%;
    background-color: #0004;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    font-size:medium;
    cursor: pointer;
}

.isCollapse {
    border-radius: 15px;
}

.collapse-content {
    padding-bottom: 5px;
}
  
.collapse-content.collapsed {
    display: none;
}

.collapsed-content.expanded {
    display: block;
}