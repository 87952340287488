.mainContainer {
    display: flex;
    flex: 1;
    height: 92vh;
    width: 100%;
}

.coming-soon {
    height: 100vh;
    width: 100%;
    line-height: 80vh;
    padding: 5px;
}

.coming-soon p {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    font-size: xx-large;
}