.projectsContainer {
    display: flex;
    flex: 1;
    height: 100vh;
}

.projectsContainer > div {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.projectsContainer > div:last-child {
    border-left: dashed 3px #430007;
}

.projectCategory {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.projectCategory:last-child {
    border-top: dashed 3px #430007;
}

.projectCategory > h1 {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 90%;
    padding-bottom: 15px;
    border-bottom: solid 3px #0004;
}

.projectCategory > div {
    display: flex;
    flex: 10;
    flex-wrap: wrap;
}

.projectLogo {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    cursor: pointer;
}

.projectLogo img {
    width: 80%;
    display: inline;
}

.projectLogo span {
    font-size: larger;
    text-align: center;
}