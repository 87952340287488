.mainContainer {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
}
.experience {
    margin-top: 65px;
}

.section {
    padding: 5px;
}

.section ul {
    list-style: none;
}
.section li::before {
    content: '- ';
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.item {
    width: 100%;
    padding-bottom: 10px;
}

.item:not(:last-child) {
    border-bottom: solid 1px #0006;
}

.item h3 {
    text-align: center;
}

.experienceNav {
    align-items: flex-end;
    position: absolute;
    top: 20px;
    right: 10px;
}

.experienceNav button {
    background-color: #93000e;
    padding: 5px;
    border-radius: 15px;
    color: white;
    font-size: larger;
    cursor: pointer;
}