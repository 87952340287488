.mainContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.meImg {
    display: flex;
    flex: 5;
    align-items: center;
    justify-content: center;
}

.meImg img {
    min-height: 0;
    min-width: 0;
    width: 50%;
    border-radius: 500px;
    border: solid 5px #460007;
}

.details {
    display: flex;
    flex: 4;
}

.details table {
    background-color: #460007;
    border-radius: 10px;
    margin: 10px;
    padding: 5px;
    width: 100%;
}

.details td {
    padding: 4px;
}

.details a {
    color: white;
}

.odd {
    background-color: #0003;
}

.detailsInfo {
    text-align: right;
}

.linkGroup {
    display: flex;
    flex: 1;
    width: 90%;
    background-color: #460007;
    margin: 10px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
}

/*.linkGroup button {
    display: flex;
    flex: 1;
    justify-content: center;
    font-size: larger;
    color: white;
    line-height: 350%;
    height: 100%;
}*/

.linkGroup button {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: larger;
    color: white;
    height: 100%;
    cursor: pointer;
}

.linkGroup .notLast {
    border-right: #282828 solid 2px;
}