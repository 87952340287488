.menuButton {
    position: fixed;
    left: 10px;
    top: 5px;
    /*padding: 5px;
    height: 60px;
    width: 60px;
    background-color: #93000e;
    border-radius: 15px;
    border: #282828 solid 2px;*/
    z-index: 10;
}

button {
    border: none;
    background-color: transparent;
}

.menu {
    position: fixed;
    background-color: #93000e;
    width: 50%;
    height: 4000px;
    left: 0;
    top: 0;
    z-index: 9;
}

.menu ul {
    display: inline;
    list-style-type: none;
    width: 100%;
}

.menu li {
    position: relative;
    margin-bottom: 25%;
    width: 100%;
    text-align: center;
}

.menu button {
    /*border-radius: 10px;
    border: solid 2px #282828;*/
    padding: 5px;
    font-size: large;
    color: white;
    text-decoration: none;
    cursor: pointer;
    width: 90%;
}

/*.menu a:visited {
    color: rgb(5, 0, 77);
}*/