.info {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
}

.info h2 {
    text-decoration: underline;
    font-weight: bold;
}

.info div {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.info button {
    color: #93000e;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.images {
    display: flex;
    flex: 1;
}

.image, .mobile-image {
    width: 100%;
    display: flex;
    position: relative;
}

.mobile-image {
    justify-content: center;
}

.image img {
    width: 100%;
}

.image span, .mobile-image span {
    position: absolute;
    border-radius: 15px;
    font-size: 0.7em;
    background-color: #000c;
    text-align: center;
    padding: 5px;
    bottom: 5px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
}

.mobile-image img {
    width: 50%;
}

.modal {
    display: flex;
    flex: 1;
    height: 80vh;
    margin: 65px 10px 10px 10px;
    background-color: #282828;
    border-radius: 15px;
    flex-direction: column;
    padding: 5px;
}

.modal > .nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.modal > .nav button {
    margin: 5px;
    border-radius: 15px;
    background-color: #460007;
    width: 3.5em;
    height: 3.5em;
}

.modal > .info {
    display: flex;
    flex: 10;
}

.modal > .tech {
    display: flex;
    flex: 10;
    flex-direction: column;
    text-align: center;
}

.modal > .info h3, .modal > .tech h3 {
    border-bottom: solid 1px #fff4;
}

.projectsButton {
    position: fixed;
    top: 10px;
    right: 5px;
    color: white;
    border-radius: 15px;
    background-color: #93000e;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
}
