.homeContainer {
    display: flex;
    flex: 1;
}

.desktop-meImage {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.desktop-meImage img {
    margin: 100px;
    min-height: 0;
    min-width: 0;
    width: 50%;
    border-radius: 500px;
    border: solid 5px #460007;
}

.desktop-info {
    display: flex;
    flex: 1.7;
    flex-direction: column;
}

.desktop-details {
    display: flex;
    flex: 4;
    margin: 25px;
    margin-left: -50px;
    background-color: #460007;
    border-radius: 15px;
    padding: 10px;
}

.desktop-details table {
    font-size: xx-large;
    width: 100%;
}

.desktop-details a {
    color: white;
}

.desktop-info td {
    padding: 15px;
}

.desktop-linkGroup {
    display: flex;
    flex: 1;
    margin: 25px;
    margin-left: -50px;
    background-color: #460007;
    border-radius: 50000px;
}

.desktop-linkGroup button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: xx-large;
    cursor: pointer;
}

.desktop-linkGroup button:not(:last-child) {
    border-right: solid 3px #282828;
}