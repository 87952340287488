.menuContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 108vh;
    background-color: #93000e;
    z-index: 10;
}

.menuContainer > div {
    position: fixed;
    top: 5px;
    left: 5px
}

.menuContainer ul {
    margin-top: 150px;
    list-style-type: none;
    margin-left: -2em;
}

.menuContainer li {
    margin-bottom: 25px;
    margin: 5px;
    padding: 5px;
    display: flex;
    justify-items: center;
    align-items: center;
    background-color: transparent;
    border-radius: 15px;
    transition: linear 0.25s ;
}

.menuContainer li:hover:not(.selected) {
    background-color: #282828;
    border-radius: 15px;
}

.menuContainer li.selected {
    background-color: #282828;
    border-radius: 15px;
}

.label {
    display: flex;
    flex: 5;
    font-size: 2em;
    align-items: center;
    justify-content: center;
}

.icon {
    display: flex;
    flex: 1;
    width: 100%;
    font-size: 3.4em;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}