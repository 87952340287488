.projects-mainContainer {
    width: 100%;
    position: fixed;
    top: 65px;
    left: 0;
}

.project {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    align-items: center;
    width: 50%;
    cursor: pointer;
}

.project img {
    width: 50%;
    display: inline;
}
.project span {
    margin-top: 5px;
    text-align: center;
}


.section {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: 5px;
    flex-shrink: 2;
}

.scroll-clue {
    position: absolute;
    bottom: 150px;
    right: 20px;
    font-size: 3em;
}