.experienceContainer {
    display: flex;
    flex: 1;
    height: 100vh;
}

.experienceContainer > div {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.experienceContainer > div h1 {
    width: 95%;
    margin: 15px;
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 3px dashed #0004;
}

.experienceContainer > div:not(:last-child) {
    border-right: dashed 3px #0004;
}

.card {
    border: solid 2px #430007;
    border-radius: 5px;
    background-color: #282828;
    margin: 10px;
}

.card > .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    font-size: larger;
}

.card > .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff8;
    text-align: right;
}

.card > .footer, .card > .header {
    background-color: #fff2;
    padding: 5px;
}

.card > .card-body {
    text-align: left;
    padding: 10px;
}

.list {
    list-style: none;
}

.list li {
    padding-bottom: 5px;
}

.list li::before {
    content: '- ';
    color: white;
    width: 1em;
    margin-left: -1em;
}