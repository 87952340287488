.accordion {
    border-radius: 18px;
    border: 2px solid #460007;
    margin: 10px;
}
.accordion .accordion-item:first-child .title {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.accordion .accordion-item:last-child .title {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.accordion .accordion-item .title {
    background-color: #460007;
    color: white !important;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: justify;
    border: none;
    outline: none;
    transition: 1.0s;
}
.accordion .accordion-item .title:after {
    content: '\02795';
    color: white !important;
    /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    float: right;
    margin-left: 5px;
}
.accordion .accordion-item .panel {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}
.accordion .accordion-item.active .title {
    background-color: #380006;
}
.accordion .accordion-item.active .title:after {
    content: '\2796';
    color: white !important;
    /* Unicode character for "minus" sign (-) */
}
.accordion .accordion-item.active .panel {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}
  